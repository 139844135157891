<template>
  <v-container class="pa-0" data-testid="fleet-additional-information">
    <v-row>
      <v-col>
        <v-card class="rounded-lg py-3 px-4 shadow-regular">
          <AdditionalInformation
            is-disabled
            data-testid="fleet-menu-additional-information"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="
        fleetVehiclesStore.vehicleDetails.acquirementType &&
        !isAcquirementTypePurchased
      "
    >
      <v-col>
        <v-card class="rounded-lg py-3 px-4 shadow-regular">
          <ContractInformation
            is-disabled
            data-testid="fleet-menu-contract-information"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isAcquirementTypePurchased">
      <v-col>
        <v-card class="rounded-lg py-3 px-4 shadow-regular">
          <PurchaseInformation
            is-disabled
            data-testid="fleet-menu-purchase-information"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isAcquirementTypePurchased">
      <v-col>
        <v-card class="rounded-lg py-3 px-4 shadow-regular">
          <InsuranceInformation
            is-disabled
            data-testid="fleet-menu-insurance-information"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="rounded-lg py-3 px-4 shadow-regular">
          <ObdInformation is-disabled data-testid="fleet-menu-obd-information">
          </ObdInformation>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="rounded-lg py-3 px-4 shadow-regular">
          <Documentation is-disabled data-testid="fleet-menu-documents" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import AdditionalInformation from '../vehicleForm/additional-information.vue';
import InsuranceInformation from '../vehicleForm/insurance-information.vue';
import ObdInformation from '../vehicleForm/obd-information.vue';
import Documentation from '../vehicleForm/documentation.vue';
import ContractInformation from '../vehicleForm/contract-information.vue';
import PurchaseInformation from '../vehicleForm/purchase-information.vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { computed } from 'vue';

const fleetVehiclesStore = useFleetVehiclesStore();

// --- Computed ---
const isAcquirementTypePurchased = computed(() => {
  const acquirementTypePurchase =
    fleetVehiclesStore.vehicleResources.acquirementTypeValues.find(
      (acquirementType) => acquirementType.Name === 'Bought'
    );

  return (
    fleetVehiclesStore.vehicleDetails.acquirementType ===
    acquirementTypePurchase?.Value
  );
});
</script>

<style scoped lang="scss"></style>
