import { render, staticRenderFns } from "./fleet-additional-information.vue?vue&type=template&id=c5f2e6f8&scoped=true&"
import script from "./fleet-additional-information.vue?vue&type=script&setup=true&lang=js&"
export * from "./fleet-additional-information.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5f2e6f8",
  null
  
)

export default component.exports